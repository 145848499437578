import gql from 'graphql-tag';

const qString = require('query-string');
const Config = require('../../Config.json');
const ConfigServices = require('../../Services/Config/Actions');

const Query = gql` query getSession($catalogue: ID!, $sessionId: ID!) {
                                              session(catalogue: $catalogue, sessionId: $sessionId)
                                              {
                                                refreshToken
                                                returnURL
                                                payloadId
                                                customer
                                                BUP
                                              }
                                            }`;

export async function SetRefreshToken(client, cat) {
    await client.query({ query: Query, variables: { catalogue: cat, sessionId: localStorage.getItem("aToken") } }).then(result => {
        if (result.data.session != null) {
          
            localStorage.setItem("rToken", result.data.session.refreshToken);
            localStorage.setItem("returnURL", result.data.session.returnURL);
            localStorage.setItem("payloadId", result.data.session.payloadId);
            localStorage.setItem("customer", result.data.session.customer);
            localStorage.setItem("BUP", result.data.session.BUP);
        }
        else
            ExpireClient();
    }).catch(ex => { var e = ex;});
}

export async function RefreshAccessToken() {
    // debugger;
    return fetch(Config.CognitoEndPoint, {
        headers: {
            "X-Amz-Target": "AWSCognitoIdentityProviderService.InitiateAuth",
            "Content-Type": "application/x-amz-json-1.1",
        },
        mode: 'cors',
        cache: 'no-cache',
        method: 'POST',
        body: JSON.stringify({
            ClientId: ConfigServices.GetConfigSetting(Config, "CognitoClientId"),
            AuthFlow: 'REFRESH_TOKEN_AUTH',
            AuthParameters: {
                REFRESH_TOKEN: localStorage.getItem('rToken')
            }
        }),
    }).then(res => { return res.json(); })
        .then(res => {
            localStorage.setItem("aToken", res.AuthenticationResult.AccessToken);
            var dt = new Date();
            dt.setSeconds(dt.getSeconds() + 3500)
            localStorage.setItem("expiry", dt);
            localStorage.setItem("rToken","expired");
            return res.AuthenticationResult.AccessToken;
        })
        .catch(ex => {
            ExpireClient();
        })
}
export function ExpireClient() {
    localStorage.removeItem("expiry");
    localStorage.removeItem("aToken");
    localStorage.removeItem("rToken");
}
export const GetRefreshToken = async () => {
    return new Promise((resolve, reject) => {
        return RefreshAccessToken().then((err, data) => {
            if (data == null)
                resolve(err);
            else
                resolve(data);
        });
    })
}

export const awsGraphqlFetch = async (uri, options) => {
    //Extract original token from Query String
    let params = qString.parse(window.location.search)
    var token = params.sessionId;

    let sToken = localStorage.getItem("aToken");
    let expiryDt = localStorage.getItem("expiry");
    let rToken = localStorage.getItem("rToken");
    //This should always hit the on the firstload, to set the original state for first token
    if (sToken != token && rToken != "expired") {
        localStorage.removeItem("rToken");
        localStorage.setItem("aToken", token);
        expiryDt = new Date();
        expiryDt.setSeconds(expiryDt.getSeconds() + 1700);
        localStorage.setItem("expiry", expiryDt);
    }
    if (rToken == "expired")
        token = sToken;

    //Give a bit of leway to refresh the token
    var dt = new Date();
    dt.setSeconds(dt.getSeconds() + 200);
    var eDt = new Date(expiryDt);

    //If Expiry Date has passed and refresh token has expired, Set client as timed out.
    if (dt > eDt && rToken == "expired") {
        ExpireClient();
        return;
    }

    //If token is nearing expiry, request a new access token
    if (dt > eDt)
        token = await GetRefreshToken();

    options.headers["Authorization"] = token;
    return fetch(uri, options);
};

export const IsTokenExpired = () => {

    let rToken = localStorage.getItem("rToken");
    let expiryDt = localStorage.getItem("expiry");
    expiryDt = new Date();
    expiryDt.setSeconds(expiryDt.getSeconds() + 1700);
    localStorage.setItem("expiry", expiryDt);

    var dt = new Date();
    dt.setSeconds(dt.getSeconds() + 200);
    var eDt = new Date(expiryDt);

    //If Expiry Date has passed and refresh token has expired, Set client as timed out.
    return (dt > eDt && rToken == "expired");
}