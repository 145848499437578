import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { SearchBar } from '../Filters/SearchBar';
import { Basket } from '../Basket/Basket';
import clsx from 'clsx';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Person from '@material-ui/icons/Person';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import { Contacts } from '../../components/Contacts/Contacts'
//import '../App.css';
const SideDrawerStyles = require("../../Services/Content/ComponentStyles").ExtractMainStyles();
const useStyles = SideDrawerStyles;

export const NavBar = ({ CatLang, Languages, setResetSearch, ResetSearch, ClearFilter, setClearFilter, setFilterModal, isFilteredFavourite, SetFilteredFavourite, SearchProducts, props, CatConfig, SessionId, searchBar, basketCount, SetBasketCount, IsQueried, SetIsProgressing, setSearchText, SetFilter }) => {


    function UpdateSearchTerm(val) {
        setSearchText(val);
    }
    const classes = useStyles();
    return (
        <AppBar position="fixed"
            className={clsx(classes.appBar, {
                [classes.appBarShift]: true,
            })} style={{ background: CatConfig.themeColour }}>
            <Toolbar>
                <Divider orientation="vertical" style={{ backgroundColor: CatConfig.themeColour , width: "5%" }} />
                <div>
                    <img className={classes.supplierLogo} src={CatConfig.imageRoute + CatConfig.supplierLogoUrl} />
                </div>
                <p className={classes.supplierTitle} >{CatConfig.name}</p>
                <div className={classes.grow} />
                <SearchBar SetFilter={SetFilter} CatLang={CatLang} Languages={Languages} setResetSearch={setResetSearch} ResetSearch={ResetSearch} SearchProducts={SearchProducts} CatConfig={CatConfig} props={props} searchBar={searchBar} ClearFilter={ClearFilter} setClearFilter={setClearFilter} setFilterModal={setFilterModal} isFilteredFavourite={isFilteredFavourite} SetFilteredFavourite={SetFilteredFavourite} onsubmit={UpdateSearchTerm}/>
                <Divider orientation="vertical" style={{ backgroundColor: CatConfig.themeColour, width: "5%" }} />
                <div className="zoom">
                    <IconButton color="primary" style={{ padding: "0px" }} aria-label="directions">
                        <Person style={{ color: 'white' }} />
                    </IconButton>
                    <p className={classes.iconText}>{localStorage.getItem("customer")}</p>
                </div>
                <Divider orientation="vertical" style={{ backgroundColor: CatConfig.themeColour, width: "2%" }} />
                <Contacts CatConfig={CatConfig} SupplierLogo={CatConfig.imageRoute + CatConfig.supplierLogoUrl} CatLang={CatLang} Languages={Languages} />
                <Divider orientation="vertical" style={{ backgroundColor: CatConfig.themeColour, width: "2%" }} />
                <div className="zoom" onClick={async (event) => { SetIsProgressing([true, "ReturnEmpty", Languages.GetText(CatLang, "WearetakingyoubacktoMyMRO")]);  }}>
                    <IconButton color="primary" style={{ padding: "0px" }} aria-label="directions">
                        <ExitToAppIcon style={{ color: 'white' }} />
                    </IconButton>
                    <p className={classes.iconText}>{Languages.GetText(CatLang, "BackToMyMRO")}</p>
                </div>
                <Divider orientation="vertical" style={{ backgroundColor: CatConfig.themeColour , width: "2%" }} />
                <div className={classes.iconButton} >
                    <Basket CatLang={CatLang} Languages={Languages} CatConfig={CatConfig} SessionId={SessionId} basketCount={basketCount} SetBasketCount={SetBasketCount} IsQueried={IsQueried} SetIsProgressing={SetIsProgressing} />
                </div>
            </Toolbar>
        </AppBar>)
};