import React, { useState, useEffect } from "react";

const useHideOnScrolled = () => {
    const [hidden, setHidden] = useState(false);

    const handleScroll = () => {
        const top = (document.documentElement.scrollTop || document.body.scrollTop) / ((document.documentElement.scrollHeight || document.body.scrollHeight) - document.documentElement.clientHeight) * 100;
        setHidden(top >= 97);
    };

    useEffect(() => {
        if (hidden)
            setHidden(false);
    }, [hidden]);


    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return hidden;
};

export default useHideOnScrolled;
