import React from 'react';
import gql from 'graphql-tag';
import main from '../Basket/Templates/PunchoutRequisition-Main';
import item from '../Basket/Templates/PunchoutRequisition-Item';
import empty from '../Basket/Templates/PurchaseRequisition-Empty';

const Audit = require('../Audit/Actions')
var util = require('util');

const UpdateQuery = gql` mutation setSession($catalogue: ID!, $sessionId: ID!, $basketcontent: [InputBasketItem]) {
  updateSession(catalogue: $catalogue, sessionId: $sessionId, basket: $basketcontent) {
    sessionId
    basket { product { productCode }}
  }
}
`;

const query = gql` query getSession($catalogue: ID!, $sessionId: ID!, $customer: String, $BUP: Boolean) {
  session(catalogue: $catalogue, sessionId: $sessionId, customer: $customer, BUP: $BUP)
  {
    sessionId
    basket {
      orderQty
      product(customer:$customer, BUP: $BUP) { 
		productCode
		description
		longDescription
		price
		currency
		per
		uom
		orderQty
		roundingQty
		minOrderQty
		leadtime
		UNSPSC
		manufName
		manufCode
		image 
        }
    }
  }
}
`;


export const GenerateBasket = (client, j, cOptions, url) => {
    var b = JSON.parse(j);
    var xRequisitionMain = main;
    var xRequisitionItem = item;
    var xReqTemplate = xRequisitionItem;
    var basketTotal = 0;
    var curr = "GBP";
    xRequisitionItem = "";

    for (var i = 0; i < b.Basket.length; i++) {
        var price = b.Basket[i].product.price / b.Basket[i].product.per;
        xRequisitionItem += util.format(xReqTemplate, b.Basket[i].orderQty, b.Basket[i].product.productCode, b.Basket[i].product.currency, price, b.Basket[i].product.description, b.Basket[i].product.uom, b.Basket[i].product.UNSPSC, b.Basket[i].product.manufCode, b.Basket[i].product.manufName, b.Basket[i].product.leadtime, b.Basket[i].product.image);
        basketTotal += (parseInt(b.Basket[i].orderQty) * parseFloat(b.Basket[i].product.price / b.Basket[i].product.per));
        curr = b.Basket[i].product.currency;
    }

    xRequisitionMain = util.format(xRequisitionMain, b.payloadId, basketTotal);

    //debugger;
    var carriageCost = CalculateCarriage(cOptions, basketTotal);

    if (carriageCost != null && carriageCost > 0)
        xRequisitionItem += util.format(xReqTemplate, "1", "ZZCARR", curr, carriageCost, "Carriage", "EA", "78102201", "N/A", "N/A", "2", "");

    Audit.Add(client, "USER RETURNED BASKET TO MYMRO", util.format(xRequisitionMain, xRequisitionItem))

    SubmitBasket(util.format(xRequisitionMain, xRequisitionItem), url);
}

function SubmitBasket(xml, rUL) {
    //Against REACT principles, but part of punchout standard is to create a form and POST it to the Return URL
    var form = document.createElement("form");
    var element1 = document.createElement("input");

    form.method = "POST";
    form.action = rUL;

    element1.value = xml;
    element1.style.display = "none";
    element1.style.width = "0px";
    element1.name = "cxml-urlencoded";
    form.appendChild(element1);
    document.body.appendChild(form);

    RemoveAccessTokens();

    form.submit();


}
function RemoveAccessTokens() {
    localStorage.removeItem("expiry");
    localStorage.removeItem("aToken");
    localStorage.removeItem("rToken");
    localStorage.removeItem("returnURL");
    localStorage.removeItem("payloadId");
}
export const CalculateCarriage = (carriageOptions, totalCost) => {
    if (carriageOptions == null)
        return null;
    var carriageTrigger = carriageOptions.carriageTrigger;
    var carriageCharge = parseFloat(carriageOptions.carriageCharge);
    if (parseFloat(totalCost) < parseFloat(carriageTrigger) || parseFloat(carriageTrigger) == 0) return carriageCharge;
    return 0;
}
export const ReturnEmptyCart = () => {
    var form = document.createElement("form");
    var element1 = document.createElement("input");
    var xEmpty = util.format(empty, localStorage.getItem("payloadId"), "0.00");

    form.method = "POST";
    form.action = localStorage.getItem("returnURL");
    
    element1.value = xEmpty;
    element1.name = "cxml-urlencoded";
    form.appendChild(element1);
    document.body.appendChild(form);

    RemoveAccessTokens();

    form.submit();
}

export const HandleRequisitionReturn = async (client, TotalPrice,Cat,SessionId,CarriageOptions) => {
    await client.query({ query, variables: { catalogue: Cat, sessionId: SessionId, customer: localStorage.getItem("customer"), BUP: localStorage.getItem("BUP") } }).then(async result => {
        await GenerateBasket(client, JSON.stringify({ payloadId: localStorage.getItem("payloadId"), BasketTotal: TotalPrice, Basket: result.data.session.basket }), CarriageOptions, localStorage.getItem("returnURL"));
    });
}

export const HandleClose = (setOpen) => {
    setOpen(false);
};
export async function ClearBasket(client, name, SessionId, RefreshBasket, setTotalPrice,SetBasketCount) {
    //debugger;
   await client.mutate({ mutation: UpdateQuery, variables: { catalogue: name, sessionId: SessionId } })
       .then(async (result) => {
           if (result.data.updateSession.basket != null) {
               RefreshBasket(result.data.updateSession.basket);
               SetBasketCount(0);
               setTotalPrice(0.00.toFixed(2));
               Audit.Add(client, "USER EMPTIED BASKET", "");
           }
        })
        .catch(err => { })
}

export const OpenBasket =  async (client, setOpen, setBasketRows, setTotalPrice, setTotalPriceWithoutCarriage, Cat, SessionId, carriageOptions) => {
    var BasketTotal = 0;
    var data = [];

    await client.query({ query, variables: { catalogue: Cat, sessionId: SessionId, customer: localStorage.getItem("customer"), BUP: localStorage.getItem("BUP") } }).then(result => {
        if (result.data.session != null) {
            for (var i = 0; i <= result.data.session.basket.length - 1; i++) {
                data.push(result.data.session.basket[i]);
                var price = (result.data.session.basket[i].product.price / result.data.session.basket[i].product.per);
                var ItemTotal = parseInt(result.data.session.basket[i].orderQty) * price;
                BasketTotal = BasketTotal + ItemTotal
            }
        }
        //debugger;
        setTotalPriceWithoutCarriage(BasketTotal.toFixed(2));
        if (BasketTotal != 0) {
            var carriageCost = CalculateCarriage(carriageOptions, BasketTotal);
            if (carriageCost != null)
                BasketTotal = BasketTotal + carriageCost;
        }
        setTotalPrice(BasketTotal.toFixed(2))
        setBasketRows(data)
        setOpen(true); });
};

export const SetBasketCount = async (client, Cat, SessionId, SetBasketCount, SetBasketCountLoaded) => {

    client.query({ query, variables: { catalogue: Cat, sessionId: SessionId } }).then(result => {
        var count;
        if (result.data.session != null)
            count = result.data.session.basket.length;
        else
            count = 0;

        if (typeof SetBasketCountLoaded != "undefined")
            SetBasketCountLoaded(true);

        SetBasketCount(count);
    });
};

export const IncrementItem = async (NewQty, productcode, e, client, CatConfig, UpdateBasket, setStatus, SessionId, onSubmit, Languages, CatLang,carriageOptions) => {
    var BasketTotal = 0;
    var BasketTotalWithoutCarriage = 0;
    var data = [];

    String.prototype.format = function (placeholders) {
        var s = this;
        for (var propertyName in placeholders) {
            var re = new RegExp('{' + propertyName + '}', 'gm');
            s = s.replace(re, placeholders[propertyName]);
        }
        return s;
    };
   // debugger;
    if (!isInt(NewQty)) { setStatus(Languages.GetText(CatLang, "Quantitymustbeawholenumber"), "error"); return; }
    await client.query({ query, variables: { catalogue: CatConfig.name, sessionId: SessionId } }).then(async (result) => {
        if (result.data.session != null) {
            for (var i = 0; i <= result.data.session.basket.length - 1; i++) {
                if ((result.data.session.basket[i].product.productCode == productcode)) {
                    if (NewQty > 0 && NewQty < 50000) {
                        if (NewQty != result.data.session.basket[i].orderQty) {
                            result.data.session.basket[i].orderQty = Number(NewQty);
                            Audit.Add(client, "USER UPDATED BASKET ITEM QTY", JSON.stringify(result.data.session.basket[i]));
                            var translatetext = Languages.GetText(CatLang, "Quantityforhasbeenupdatedto")
                            setStatus(translatetext.format({ param1: productcode, param2: NewQty }), "success");
                        }
                    }
                    else {
                        setStatus(Languages.GetText(CatLang, "Quantityhastobebetween"), "error");
                        e.target.value = result.data.session.basket[i].orderQty
                    }
                }
                var ItemTotal = parseInt(result.data.session.basket[i].orderQty) * (result.data.session.basket[i].product.price / result.data.session.basket[i].product.per);
                BasketTotal = BasketTotal + ItemTotal
                data.push({ orderQty: result.data.session.basket[i].orderQty, productCode: result.data.session.basket[i].product.productCode });
            }
            //debugger;
            BasketTotalWithoutCarriage = BasketTotal;
            if (BasketTotal != 0) {
                var carriageCost = CalculateCarriage(CatConfig.carriageOptions, BasketTotal);
                if (carriageCost != null)
                    BasketTotal = BasketTotal + carriageCost;
            }
            await UpdateBasket({ variables: { catalogue: CatConfig.name, sessionId: SessionId, basketcontent: data } })
            await onSubmit([BasketTotal, BasketTotalWithoutCarriage]);
        }
    });

}


export const RemoveBasketItem = async (client, code, CatConfig, UpdateBasket, SessionId, onSubmit, SetBasketCount) => {
    var BasketTotal = 0;
    var BasketTotalWithoutCarriage = 0;
    var data = [];
    var BasketLines = [];
    await client.query({ query, variables: { catalogue: CatConfig.name, sessionId: SessionId } }).then(async result => {
        if (result.data.session != null) {
            for (var i = 0; i <= result.data.session.basket.length - 1; i++)
                if (result.data.session.basket[i].product.productCode != code) {
                    data.push({ orderQty: result.data.session.basket[i].orderQty, productCode: result.data.session.basket[i].product.productCode });
                    BasketLines.push(result.data.session.basket[i]);
                    var ItemTotal = parseInt(result.data.session.basket[i].orderQty) * (result.data.session.basket[i].product.price / result.data.session.basket[i].product.per);
                    BasketTotal = BasketTotal + ItemTotal;
                } else
                    Audit.Add(client, "USER REMOVED BASKET ITEM", code);
        }
        BasketTotalWithoutCarriage = BasketTotal;
        if (BasketTotal != 0) {
            var carriageCost = CalculateCarriage(CatConfig.carriageOptions, BasketTotal);
            if (carriageCost != null)
                BasketTotal = BasketTotal + carriageCost;
        }
        SetBasketCount(BasketLines.length);
        onSubmit([BasketTotal, BasketTotalWithoutCarriage]);
       await UpdateBasket({ variables: { catalogue: CatConfig.name, sessionId: SessionId, basketcontent: data } })
    });
}
function isInt(value) {
    var x;
    //debugger;
    return isNaN(value) ? !1 : (x = parseFloat(value), (0 | x) === x);
}


